import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../firebase';
import SendMessage from '../components/SendMessage';
import firebase from 'firebase/compat/app';
import { useAuth } from '../context/AuthContext';

import Modal from 'react-modal/lib/components/Modal';
import { Form, Button, Card } from 'react-bootstrap';

import './chat.css';

function Chat({ currentMsgReceiver }) {
  console.log(currentMsgReceiver);
  const { currentUser, logout } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [msgReceiver, setMsgReceiver] = useState(currentMsgReceiver);
  console.log(msgReceiver);

  const handleNewChat = () => {
    setModalIsOpen(true);
  };

  const handleEnterEmail = (event) => {
    event.preventDefault();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (currentMsgReceiver === undefined) {
      setModalIsOpen(true);
    }
  }, []);

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     console.log('User pressed: ', event.key);

  //     if (event.key === 'Enter') {
  //       event.preventDefault();

  //       setModalIsOpen(false);
  //     }
  //   };

  //   document.addEventListener('keydown', keyDownHandler);

  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, []);

  var user = firebase.auth().currentUser;
  console.log(user.uid);
  const scroll = useRef();
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    // this is where the code runs
    db.collection(currentUser.email)
      .orderBy('timestamp')
      .onSnapshot((snapshot) => {
        // every time a new post is added, this code runs
        setMessages(
          snapshot.docs.map((doc) => ({ id: doc.id, message: doc.data() }))
        );
      });
  }, []);
  console.log(auth.currentUser.uid);
  console.log(messages);

  console.log(msgReceiver);
  return (
    <div>
      <h2 className="chat__currentChat">Current Chat: {msgReceiver}</h2>
      <div className="chat__btn">
        <Button
          className="btn__color btn-primary mt-2"
          onClick={() => handleNewChat()}
        >
          Start New Chat
        </Button>
      </div>

      <div className="msgs">
        {messages.map(({ id, message, photoURL, uid }) => (
          <div>
            <div
              key={id}
              className={`msg ${
                message.msgSender === currentUser.email ? 'sent' : 'received'
              }`}
            >
              <img src={photoURL} alt="" />
              <div>
                <p>{message.text}</p>
                <p className="chat__sendersName">{message.msgSender}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <SendMessage scroll={scroll} msgReceiver={msgReceiver} />
      <div ref={scroll}></div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-3">Start New Chat</h2>

              <Form onSubmit={handleEnterEmail}>
                <Form.Group id="msgReceiver">
                  <Form.Label className="mb-0 mt-2">
                    Enter an email address and get started ...
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="enter email"
                    onChange={(event) => setMsgReceiver(event.target.value)}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
          <Button
            type="submit"
            className="modal__closeWindowBtn btn-primary mt-2"
            onClick={() => setModalIsOpen(false)}
          >
            Close Window
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Chat;
