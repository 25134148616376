import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import { AuthProvider } from './context/AuthContext';
import BusinessProfileTemplate from './pages/BusinessProfileTemplate';
import CreateProfile from './components/CreateProfile';
import FillinBusinessProfile from './pages/FillinBusinessProfile';
import UpdateProfile from './components/UpdateProfile';
import FillinFeed from './pages/FillinFeed';
// import Chat from './pages/Chat';
import FillinChat from './fillinChat/FillinChat';
import Sidebar from './fillinChat/Sidebar';
import Chat from './fillinChat/Chat';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <AuthProvider>
          <Container
          // className="d-flex justify-content-center"
          // style={{ minHeight: '100vh' }}
          >
            <div>
              {/* className="w-100" style={{ maxWidth: '400px' }} */}
              <Routes>
                <Route exact path="/" element={<Login />}></Route>
                <Route
                  exact
                  path="/businessprofiletemplate"
                  element={<BusinessProfileTemplate />}
                ></Route>
                <Route path="/signup" element={<Signup />}></Route>
                <Route path="/dashboard" element={<Dashboard />}></Route>
                <Route
                  path="/forgotpassword"
                  element={<ForgotPassword />}
                ></Route>
                <Route
                  path="/createprofile"
                  element={<CreateProfile />}
                ></Route>
                <Route
                  path="/fillinbusinessprofile"
                  element={<FillinBusinessProfile />}
                ></Route>
                <Route
                  path="/updateprofile"
                  element={<UpdateProfile />}
                ></Route>
                <Route path="/fillinfeed" element={<FillinFeed />}></Route>
                {/* <Route path="/chat" element={<Chat />}></Route> */}

                <Route path="/fillinchat" element={<FillinChat />}>
                  <Route
                    path="/fillinchat/sidebar"
                    element={<Sidebar />}
                  ></Route>
                  <Route
                    path="/fillinchat/rooms/:roomId"
                    element={<Chat />}
                  ></Route>
                </Route>
              </Routes>
            </div>
          </Container>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
