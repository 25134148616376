import React, { useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

import './businessProfileTemplate.css';
import logoPlaceholderImage from '../assets/logoPlaceholder.png';

const templateOpeningsList = ['Job1', 'Job2', 'Job3'];

export default function BusinessProfileTemplate() {
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    setError('');

    try {
      await logout();
      navigate('/');
    } catch {
      setError('Failed to log out');
    }
  }

  return (
    <>
      {/* add welcome */}
      <div className="welcome">
        <h1>Welcome to fillin ...</h1>
        <p>Crowd Source your Staffing Needs</p>
      </div>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          <p className="text-center">Step 1: Create the perfect profile</p>
          <div>
            <div className="newProfile__container">
              <div className="newProfile__posts">
                <div>
                  <img
                    className="newProfile__image"
                    alt=""
                    src={logoPlaceholderImage}
                  />
                </div>
                <div className="newProfile__text">
                  <p className="newProfile__businessName">Business Name</p>
                  <p className="newProfile__location">City, State</p>
                  <p className="newProfile__contact">Contact</p>

                  <p className="newProfile__openingsTitle">Openings:</p>

                  <div className="newProfile__openingsList">
                    {templateOpeningsList?.map((openings) => (
                      <p className="newProfile__openings">• {openings}</p>
                    ))}
                    <p className="newProfile__email">{currentUser?.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {error && <Alert variant="danger">{error}</Alert>}
          <Link to="/createprofile" className="btn btn-primary w-100 mt-3">
            Create Profile
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  );
}
