import React from 'react';

import Sidebar from './Sidebar';
import Chat from '../fillinChat/Chat';

import './fillinChat.css';

function FillinChat() {
  return (
    <div className="app">
      <div className="app__body">
        <Sidebar />
        <Chat />
      </div>
    </div>
  );
}

export default FillinChat;
