import React, { useState, useEffect } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase';

import './fillinBusinessProfile.css';

export default function FillinBusinessProfile() {
  const [businessName, setBusinessName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [openings, setOpenings] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [profileId, setProfileId] = useState('');
  const [hiringFillins, setHiringFillins] = useState(false);
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetProfileData();
  }, []);

  async function handleGetProfileData() {
    const q = query(
      collection(db, 'fillinBusinesses'),
      where('email', '==', currentUser.email)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      console.log(doc.id, ' => ', doc.data());
      console.log(doc.data());
      setBusinessName(doc.data().businessName);
      setCity(doc.data().city);
      setState(doc.data().state);
      setContact(doc.data().contact);
      setPhone(doc.data().phone);
      setBusinessEmail(doc.data().businessEmail);
      setOpenings(doc.data().openings);
      setImageURL(doc.data().imageURL);
      setProfileId(doc.id);
    });
  }

  useEffect(() => {
    handleHiringFillinsField();
  }, [hiringFillins]);

  async function handleHiringFillinsField() {
    const hiringFillinsField = doc(db, 'fillinBusinesses', profileId);

    // Set the "avialable" field
    await updateDoc(hiringFillinsField, {
      hiringFillins: hiringFillins,
    });
  }

  async function handleLogout() {
    setError('');

    try {
      await logout();
      navigate('/');
    } catch {
      setError('Failed to log out');
    }
  }

  const handleHiringFillins = (yesNo) => {
    setHiringFillins(yesNo);
    console.log(hiringFillins);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          <p className="text-center">Step 2: Find Fillins</p>
          <div>
            <div className="newProfile__container">
              <div className="newProfile__posts">
                <div>
                  <img className="newProfile__image" alt="" src={imageURL} />
                </div>
                <div className="newProfile__text">
                  <p className="newProfile__businessName">{businessName}</p>
                  <p className="newProfile__location">
                    {city}, {state}
                  </p>
                  <p className="newProfile__contact">{contact}</p>
                  <p className="newProfile__phone">{phone}</p>
                  <p className="newProfile__email">{businessEmail}</p>
                  <p className="newProfile__openingsTitle">Openings:</p>

                  <div className="newProfile__openingsList">
                    {openings?.map((opening) => (
                      <p className="newProfile__openings">• {opening}</p>
                    ))}
                  </div>
                  <div>
                    <p>{currentUser?.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!hiringFillins ? (
            <p className="availability__label" style={{ color: 'red' }}>
              Find Fillins
            </p>
          ) : (
            <p className="availability__label" style={{ color: 'green' }}>
              Find Fillins
            </p>
          )}
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
                onClick={() => handleHiringFillins(true)}
              />
              <label class="form-check-label" for="inlineRadio1">
                YES
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
                checked
                onClick={() => handleHiringFillins(false)}
              />
              <label class="form-check-label" for="inlineRadio2">
                NO
              </label>
            </div>
          </div>
          <Link to="/fillinfeed">
            <Button className="btn btn-primary w-100 mt-3">Find Fillins</Button>
          </Link>
          <Link to="/chat">
            <Button className="btn btn-primary w-100 mt-3">Start Chat</Button>
          </Link>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/updateprofile">
          <Button
            className="fillinBusinessProfile__updateProfile"
            variant="link"
          >
            Update Profile
          </Button>
        </Link>
        <Button
          className="fillinBusinessProfile__logOut"
          variant="link"
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </div>
    </>
  );
}
