import React, { useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { storage, db } from '../firebase';
import firebase from 'firebase/compat/app';
import { currentUser } from '../context/AuthContext';

import './createProfile.css';

let openingsList = [];

export default function CreateProfile() {
  const [businessName, setBusinessName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [contact, setContact] = useState('');

  const [openings, setOpenings] = useState([]);
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const { currentUser } = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleOpenings = (experience) => {
    let index = openingsList.indexOf(experience);
    if (index > -1) {
      openingsList.splice(index, 1);
    } else {
      openingsList.push(experience);
    }
    console.log(openingsList);
    setOpenings(openingsList);
  };

  function handleSubmit(e) {
    e.preventDefault();
  }

  const handleChooseFile = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    console.log(image.name);
    const storageRef = ref(storage, `/images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
        alert(error.message);
      },
      () => {
        // .ref('images')
        // .child(image.name)
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // post image inside db
          db.collection('fillinBusinesses').add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            email: currentUser.email,
            hiringFillins: false,
            businessName: businessName,
            city: city,
            state: state,
            contact: contact,
            openings: openings,
            imageURL: url,
          });

          setProgress(0);

          setImage(null);
        });
      }
    );
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-3">Create Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="firstName">
              <Form.Label className="mb-0 mt-2">
                Business Name or Individual Hiring
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="enter business name or individual's name"
                onChange={(event) => setBusinessName(event.target.value)}
              />
            </Form.Group>
            <Form.Group id="city">
              <Form.Label className="mb-0 mt-2">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter city"
                onChange={(event) => setCity(event.target.value)}
              />
            </Form.Group>

            <Form.Group id="state">
              <Form.Label className="mb-0 mt-2">State</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter state"
                onChange={(event) => setState(event.target.value)}
              />
            </Form.Group>
            <Form.Group id="contact">
              <Form.Label className="mb-0 mt-2">Contact</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter name of person to contact"
                onChange={(event) => setContact(event.target.value)}
              />
            </Form.Group>
            <p className="mt-2 mb-0">Openings (check all that apply)</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onClick={() => handleOpenings('busser')}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Busser
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onClick={() => handleOpenings('host')}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Host
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onClick={() => handleOpenings('waiter')}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Waiter
              </label>
            </div>

            <hr />

            <p className="mt-2">Upload Profile Picture</p>
            <div className="choose__file">
              <input
                className="chooseFile__button"
                type="file"
                onChange={handleChooseFile}
              />
            </div>
            <div className="upload__button">
              <Button onClick={handleUpload}>Upload</Button>
            </div>
            <div className="upload__progressBar">
              <progress
                className="imageUpload__progress"
                value={progress}
                max="100"
              />
            </div>

            <hr />

            <Link to="/fillinbusinessprofile">
              <Button disabled={loading} className="w-100 mt-3" type="submit">
                Update
              </Button>
            </Link>
          </Form>
        </Card.Body>
      </Card>
      <div className="cancel__btn w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  );
}
