import React, { useState } from 'react';
import { db, auth } from '../firebase';
import firebase from 'firebase/compat/app';
import { Input, Button } from '@material-ui/core';
import { useAuth } from '../context/AuthContext';

import { Link } from 'react-router-dom';

import './sendMessage.css';

function SendMessage({ scroll, msgReceiver }) {
  const [msg, setMsg] = useState('');
  const { currentUser, logout } = useAuth();

  async function sendMessage(e) {
    e.preventDefault();
    const { uid, photoURL } = auth.currentUser;

    await db.collection(msgReceiver).add({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      uid: firebase.auth().currentUser,
      text: msg,
      msgSender: currentUser.email,
      msgReceiver: msgReceiver,
      photoURL,
      uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setMsg('');
    scroll.current.scrollIntoView({ behavior: 'smooth' });

    await db.collection(currentUser.email).add({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      uid: firebase.auth().currentUser,
      text: msg,
      msgSender: currentUser.email,
      msgReceiver: msgReceiver,
      photoURL,
      uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setMsg('');
    scroll.current.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <div>
      <form onSubmit={sendMessage}>
        <div className="sendMsg">
          <div>
            <Input
              style={{
                width: '78%',
                fontSize: '15px',
                fontWeight: '550',
                marginLeft: '0px',
                marginBottom: '-3px',
              }}
              className="chat__input"
              placeholder="Message..."
              type="text"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
          </div>
          <div>
            <Button
              style={{
                width: '18%',
                fontSize: '15px',
                fontWeight: '550',
                margin: '4px 5% -13px 5%',
                maxWidth: '600px',
              }}
              type="submit"
            >
              Send
            </Button>
            <div>
              <Link to="/FillinBusinessProfile">
                <Button
                  style={{
                    width: '125px',
                    fontSize: '15px',
                    fontWeight: '550',
                    margin: '4px 5% -25px -180px',
                    maxWidth: '600px',
                  }}
                  variant="link"
                >
                  exit chat
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SendMessage;
