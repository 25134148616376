import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { initializeApp } from '@firebase/app';
import { getStorage } from '@firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB4mrXVGa7Iud2dxd7CwTKygjCUgBFOJvo',
  authDomain: 'fillin-web-app.firebaseapp.com',
  projectId: 'fillin-web-app',
  storageBucket: 'fillin-web-app.appspot.com',
  messagingSenderId: '29936111927',
  appId: '1:29936111927:web:bebcd70e4742b348d44ab2',
  measurementId: 'G-21CSN1353Z',
};

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyB4mrXVGa7Iud2dxd7CwTKygjCUgBFOJvo',
  authDomain: 'fillin-web-app.firebaseapp.com',
  projectId: 'fillin-web-app',
  storageBucket: 'fillin-web-app.appspot.com',
  messagingSenderId: '29936111927',
  appId: '1:29936111927:web:bebcd70e4742b348d44ab2',
  measurementId: 'G-21CSN1353Z',
});

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
// const storage = firebase.getStorage();

export { db, auth, provider };
