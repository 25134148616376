import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { storage, db } from '../firebase';
import firebase from 'firebase/compat/app';
import { currentUser } from '../context/AuthContext';
// import { useAuth } from "../context/AuthContext"

import './createProfile.css';

let openingsList = [];

export default function UpdateProfile() {
  // const [email, setEmail] = useState(currentUser.email);
  const [businessName, setBusinessName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [openings, setOpenings] = useState([]);

  const [experience, setExperience] = useState([]);
  const [openingsList, setopeningsList] = useState([]);
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [profileId, setProfileId] = useState('');
  const [progress, setProgress] = useState(0);

  const { currentUser } = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetProfileData();
  }, []);

  async function handleGetProfileData() {
    const q = query(
      collection(db, 'fillinBusinesses'),
      where('email', '==', currentUser.email)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      console.log(doc.id, ' => ', doc.data());
      // setFirstName(doc.data().firstName);
      // setLastName(doc.data().lastName);
      setCity(doc.data().city);
      setState(doc.data().state);
      console.log(doc.data().experience);
      setProfileId(doc.id);
      console.log(profileId);
    });
  }

  useEffect(() => {
    handleBusinessName();
  }, [businessName]);

  async function handleBusinessName() {
    const businessNameField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(businessNameField, {
      businessName: businessName,
    });
  }

  useEffect(() => {
    handleCity();
  }, [city]);

  async function handleCity() {
    const cityField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(cityField, {
      city: city,
    });
  }

  useEffect(() => {
    handleState();
  }, [state]);

  async function handleState() {
    const stateField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(stateField, {
      state: state,
    });
  }

  useEffect(() => {
    handleContact();
  }, [contact]);

  async function handleContact() {
    const contactField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(contactField, {
      contact: contact,
    });
  }

  useEffect(() => {
    handlePhone();
  }, [phone]);

  async function handlePhone() {
    const phoneField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(phoneField, {
      phone: phone,
    });
  }

  useEffect(() => {
    handleBusinessEmail();
  }, [businessEmail]);

  async function handleBusinessEmail() {
    const businessEmailField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(businessEmailField, {
      businessEmail: businessEmail,
    });
  }

  useEffect(() => {
    handleOpenings();
  }, [openings]);

  async function handleOpenings() {
    const openingsField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(openingsField, {
      openings: openings,
    });
    console.log(openings);
  }

  useEffect(() => {
    handleImageURL();
  }, [imageURL]);

  async function handleImageURL() {
    const imageURLField = doc(db, 'fillinBusinesses', profileId);
    await updateDoc(imageURLField, {
      imageURL: imageURL,
    });
  }

  const handleExperienceArray = (experience) => {
    let index = openingsList.indexOf(experience);
    if (index > -1) {
      openingsList.splice(index, 1);
    } else {
      openingsList.push(experience);
    }
    console.log(openingsList);
    setOpenings(openingsList);
    console.log(openings);
  };

  function handleSubmit(e) {
    e.preventDefault();
  }

  const handleChooseFile = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    console.log(image.name);
    const storageRef = ref(storage, `/images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
        alert(error.message);
      },
      () => {
        // .ref('images')
        // .child(image.name)
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          setImageURL(url);
          console.log(imageURL);
          setProgress(0);
          setImage(null);
        });
      }
    );
  };

  // document id = currentUser.email

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-3">Update Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="businessName">
              <Form.Label className="mb-0 mt-2">
                Business Name or Individual's Name
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="enter business name or individual's name"
                onChange={(event) => setBusinessName(event.target.value)}
              />
            </Form.Group>

            <Form.Group id="city">
              <Form.Label className="mb-0 mt-2">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter city"
                onChange={(event) => setCity(event.target.value)}
              />
            </Form.Group>

            <Form.Group id="state">
              <Form.Label className="mb-0 mt-2">State</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter state"
                onChange={(event) => setState(event.target.value)}
              />
            </Form.Group>
            <Form.Group id="contact">
              <Form.Label className="mb-0 mt-2">Contact</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter contact person"
                onChange={(event) => setContact(event.target.value)}
              />
            </Form.Group>
            <Form.Group id="phone">
              <Form.Label className="mb-0 mt-2">Phone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="enter phone"
                onChange={(event) => setPhone(event.target.value)}
              />
            </Form.Group>
            <Form.Group id="businessEmail">
              <Form.Label className="mb-0 mt-2">Business Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="enter business email"
                onChange={(event) => setBusinessEmail(event.target.value)}
              />
            </Form.Group>
            <p className="mt-2 mb-0">Work Experience (check all that apply)</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onClick={() => handleExperienceArray('busser')}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Busser
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onClick={() => handleExperienceArray('host')}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Host
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onClick={() => handleExperienceArray('waiter')}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Waiter
              </label>
            </div>

            <hr />

            <p className="mt-2">Upload Profile Picture</p>
            <div className="choose__file">
              <input
                className="chooseFile__button"
                type="file"
                onChange={handleChooseFile}
              />
            </div>
            <div className="upload__button">
              <Button onClick={handleUpload}>Upload</Button>
            </div>
            <div className="upload__progressBar">
              <progress
                className="imageUpload__progress"
                value={progress}
                max="100"
              />
            </div>

            <hr />

            <Link to="/fillinbusinessprofile">
              <Button disabled={loading} className="w-100 mt-3" type="submit">
                Update
              </Button>
            </Link>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  );
}
