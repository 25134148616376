import React, { useState, useEffect } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import Chat from '../pages/Chat';

import './fillinFeed.css';

import profilePic from '../assets/profilePicPlaceHolder.jpeg';

export default function FillinFeed() {
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    db.collection('fillins')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        setPosts(
          snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() }))
        );
      });
  }, []);

  console.log(posts);

  const navigate = useNavigate();
  async function handleLogout() {
    setError('');

    try {
      await logout();
      navigate('/');
    } catch {
      setError('Failed to log out');
    }
  }

  const [msgReceiver, setMsgReceiver] = useState('');
  const handleMsgReceiver = (msgReceiver) => {
    setMsgReceiver(msgReceiver);
    console.log(msgReceiver);
  };

  if (msgReceiver === '') {
    return (
      <>
        <h1 className="fillinFeed__title">Available Fillins</h1>
        {posts?.map(({ id, post }) => (
          <div>
            <Card>
              <Card.Body>
                <div>
                  <div className="newProfile__container">
                    <div className="newProfile__posts">
                      <div>
                        <img
                          className="newProfile__image"
                          alt=""
                          src={post.imageURL}
                        />
                      </div>
                      <div className="newProfile__text">
                        <p className="newProfile__name">
                          {post.firstName} {post.lastName}
                        </p>

                        <p className="newProfile__location">
                          {post.city}, {post.state}
                        </p>
                        <p className="newProfile__experience">Experience:</p>

                        <div className="newProfile__experienceList">
                          {post.experience?.map((experiences) => (
                            <p className="newProfile__experiences">
                              • {experiences}
                            </p>
                          ))}
                        </div>
                        <div>
                          <p className="fillinFeed__email">{post.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn__position">
                  <Button
                    className="w-100 mt-3"
                    type="submit"
                    onClick={() => handleMsgReceiver(post.email)}
                  >
                    chat
                  </Button>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}
              </Card.Body>
            </Card>
            <div className="logOut__btn__position w-100 text-center mt-2">
              <Button variant="link" onClick={handleLogout}>
                Log Out
              </Button>
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return <Chat currentMsgReceiver={msgReceiver} />;
  }
}
